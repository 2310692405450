import React, { FC } from "react"

interface SvgProps {
  width?: number
  height?: number
  className?: string
}

const Check: FC<SvgProps> = ({ width = 19, height = 19, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 19 19"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
    className={className}
  >
    <path
      d={
        "M16.2598 8.08691V15.2119C16.2598 15.5103 16.1412 15.7964 15.9303 16.0074C15.7193 16.2184 15.4331 16.3369 15.1348 16.3369H3.88477C3.5864 16.3369 3.30025 16.2184 3.08927 16.0074C2.87829 15.7964 2.75977 15.5103 2.75977 15.2119V3.96191C2.75977 3.66355 2.87829 3.3774 3.08927 3.16642C3.30025 2.95544 3.5864 2.83691 3.88477 2.83691H11.7598"
      }
      stroke={"#0A9A22"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M6.50977 8.08691L10.2598 11.0869L15.8848 3.21191"}
      stroke={"#0A9A22"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default Check
