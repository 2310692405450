import React, { FC } from "react"

interface SvgProps {
  width?: number
  height?: number
  className?: string
}

const Payments: FC<SvgProps> = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 20 20"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
    className={className}
  >
    <path
      d={"M15.8334 6.25033V2.91699H4.16675V6.25033"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M17.9166 11.25V6.25H2.08325V17.0833H17.9166"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M12.0833 11.25L10 14.1667H17.9167"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M10.8334 8.75H9.16675"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M6.66667 8.75H5"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default Payments
