import React, { FC } from "react"

interface SvgProps {
  width?: number
  height?: number
  className?: string
}

const Reports: FC<SvgProps> = ({ width = 26, height = 28, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 26 28"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
    className={className}
  >
    <g filter={"url(#filter0_d_12885_84073)"}>
      <path
        d={"M5.57031 3.43262V18.4326H20.5703"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M8.90381 13.4326V15.0993"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M12.2368 10.0996V15.0996"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M15.5703 3.43262V15.0993"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M18.9038 6.76562V15.099"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </g>
    <defs>
      <filter
        id={"filter0_d_12885_84073"}
        x={"-0.929688"}
        y={"0.932617"}
        width={"28"}
        height={"28"}
        filterUnits={"userSpaceOnUse"}
        colorInterpolationFilters={"sRGB"}
      >
        <feFlood floodOpacity={"0"} result={"BackgroundImageFix"} />
        <feColorMatrix
          in={"SourceAlpha"}
          type={"matrix"}
          values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"}
          result={"hardAlpha"}
        />
        <feOffset dy={"4"} />
        <feGaussianBlur stdDeviation={"2"} />
        <feComposite in2={"hardAlpha"} operator={"out"} />
        <feColorMatrix type={"matrix"} values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"} />
        <feBlend
          mode={"normal"}
          in2={"BackgroundImageFix"}
          result={"effect1_dropShadow_12885_84073"}
        />
        <feBlend
          mode={"normal"}
          in={"SourceGraphic"}
          in2={"effect1_dropShadow_12885_84073"}
          result={"shape"}
        />
      </filter>
    </defs>
  </svg>
)

export default Reports
