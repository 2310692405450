import Image from "next/image"
import Link from "next/link"
import React from "react"

import FacebookIcon from "@modules/site/components/partials/icons/Facebook"
import InstagramIcon from "@modules/site/components/partials/icons/Instagram"
import LinkedInIcon from "@modules/site/components/partials/icons/Linkedin"
import TwitterIcon from "@modules/site/components/partials/icons/Twitter"

import EmailIcon from "./partials/icons/Email"

const navigation = {
  features: [
    { name: "Inventory", href: "/explore/inventory-management" },
    { name: "Reports", href: "/explore/reports" },
    { name: "Waivers", href: "/explore/waivers" },
    { name: "Payments", href: "/sell/payments" },
  ],

  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/SmartRezBooking",
      icon: () => <FacebookIcon />,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/smartrezbooking/",
      icon: () => <InstagramIcon />,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/SmartRez",
      icon: () => <TwitterIcon />,
    },
  ],
}

export default function Footer() {
  const today = new Date()
  const year = today.getFullYear()
  return (
    <footer className={"bg-white"} aria-labelledby={"footer-heading"}>
      <h2 id={"footer-heading"} className={"sr-only"}>
        Footer
      </h2>
      <div className={"w-full px-6 pt-16 pb-8 mx-auto max-w-7xl sm:pt-24 lg:px-8 lg:pt-32"}>
        <div className={"mx-auto max-w-7xl"}>
          <div className={"grid px-6 mt-16 space-y-4 lg:grid-cols-2 place-items-start xl:mt-0"}>
            <div className={"max-w-sm space-y-4"}>
              <Image
                className={"w-[143px] h-[38px]"}
                src={"/site/logo.png"}
                alt={""}
                width={143}
                height={38}
                unoptimized
                quality={100}
              />
              <p className={"text-neutral-500 text-md"}>
                Vertically integrated customizable online reservation platform, streamline booking
                operations with a one-stop solution for all your business needs.
              </p>
              <div className={"flex space-x-6 md:order-2"}>
                {navigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={"text-gray-400 hover:text-gray-500"}
                  >
                    <span className={"sr-only"}>{item.name}</span>
                    <item.icon aria-hidden={"true"} />
                  </a>
                ))}
              </div>
            </div>
            <div className={"grid"}>
              <div>
                <h3 className={"text-sm font-semibold leading-6 text-gray-900"}>Features</h3>
                <ul
                  role={"list"}
                  className={
                    "grid mt-4 space-y-4 lg:space-y-0 lg:gap-y-4 lg:gap-x-8 lg:grid-cols-2 place-items-start"
                  }
                >
                  {navigation.features.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className={"text-sm leading-6 text-gray-600 hover:text-primary-600"}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Subscribe to Newsletter</h3>
              <div className="mt-6 space-y-4">
                <div>
                  <p className="mt-2 text-sm leading-6 text-gray-600">
                    Enter your email address for receiving valuable newsletters.
                  </p>
                </div>
                <form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <div className="flex items-center">
                    <input
                      type="email"
                      name="email-address"
                      id="email-address"
                      autoComplete="email"
                      required
                      className="w-full min-w-0 px-3 py-2 text-base text-gray-900 bg-white border-0 shadow-sm appearance-none rounded-l-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:w-56 sm:text-sm sm:leading-6"
                      placeholder="Enter your email"
                    />
                    <button
                      type="submit"
                      className="flex items-center justify-center px-3 py-2 text-sm font-semibold text-white shadow-sm rounded-r-md bg-primary-600 hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
                      <EmailIcon />
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
          <div />
        </div>
        <div className={"pt-8 mt-8 border-t border-gray-900/10 "} />
        <div className={"flex items-center justify-between mx-auto max-w-7xl"}>
          <div className={"space-x-8 text-sm text-neutral-500"}>
            <Link href={"/privacy-policy"}>Privacy Policy</Link>
            <Link href={"/terms-and-conditions"}>Terms & Conditions</Link>
          </div>
          <div className={"text-sm text-neutral-500"}>
            <p>Copyright &copy; {year} - SmartRez</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
