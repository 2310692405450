import React, { FC } from "react"

interface SvgProps {
  width?: number
  height?: number
  className?: string
}

const BookingWidget: FC<SvgProps> = ({ width = 20, height = 20, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 20 20"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
    className={className}
  >
    <path
      d={"M10 5L15.8333 8.33333V15L10 18.3333L4.16667 15V8.33333L10 5Z"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M10 2.5V5"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M4.16667 8.33301L10 11.6663L15.8333 8.33301"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M15.8333 15L18.3333 16.25"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M1.66667 16.25L4.16667 15"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M10 11.667V18.3337"}
      stroke={"#2A5DE3"}
      strokeWidth={1.5}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default BookingWidget
