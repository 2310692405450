import React, { FC } from "react"

interface SvgProps {
  width?: number
  height?: number
  className?: string
}

const Integrations: FC<SvgProps> = ({ width = 21, height = 21, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 21 21"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
    className={className}
  >
    <g clipPath={"url(#clip0_12885_84078)"}>
      <path
        d={
          "M13.8205 15.0993H6.32048L3.40381 8.32845L4.23714 4.68262H15.9038L16.7371 8.32845L13.8205 15.0993Z"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M7.57031 2.59961V4.68294"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M12.5703 2.59961V4.68294"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M10.0703 15.0996V19.2663H16.737V16.1963"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M7.57031 9.68262H12.5703"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </g>
    <defs>
      <clipPath id={"clip0_12885_84078"}>
        <rect
          width={"20"}
          height={"20"}
          fill={"white"}
          transform={"translate(0.0703125 0.932617)"}
        />
      </clipPath>
    </defs>
  </svg>
)

export default Integrations
