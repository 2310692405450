import React, { FC } from "react"

interface SvgProps {
  width?: number
  height?: number
  className?: string
}

const Waivers: FC<SvgProps> = ({ width = 21, height = 21, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 21 21"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
    className={className}
  >
    <g clipPath={"url(#clip0_12885_84067)"}>
      <path
        d={
          "M3.40381 3.43294C3.40381 3.21193 3.49161 2.99997 3.64789 2.84369C3.80417 2.68741 4.01613 2.59961 4.23714 2.59961H15.9038C16.1248 2.59961 16.3368 2.68741 16.4931 2.84369C16.6493 2.99997 16.7371 3.21193 16.7371 3.43294V18.4329C16.7371 18.654 16.6493 18.8659 16.4931 19.0222C16.3368 19.1785 16.1248 19.2663 15.9038 19.2663H4.23714C4.01613 19.2663 3.80417 19.1785 3.64789 19.0222C3.49161 18.8659 3.40381 18.654 3.40381 18.4329V3.43294Z"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M6.73682 2.59961V19.2663"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M10.0703 5.93262H13.4036"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M10.0703 9.26562H13.4036"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M4.23682 2.59961H9.23682"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M4.23682 19.2656H9.23682"}
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </g>
    <defs>
      <clipPath id={"clip0_12885_84067"}>
        <rect
          width={"20"}
          height={"20"}
          fill={"white"}
          transform={"translate(0.0703125 0.932617)"}
        />
      </clipPath>
    </defs>
  </svg>
)

export default Waivers
