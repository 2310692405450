import React, { FC } from "react"

interface SvgProps {
  width?: number
  height?: number
  className?: string
}

const Staff: FC<SvgProps> = ({ width = 21, height = 21, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 21 21"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
    className={className}
  >
    <g clipPath={"url(#clip0_12885_84062)"}>
      <path
        d={
          "M10.0705 9.26595C10.4535 9.26595 10.8328 9.19051 11.1866 9.04393C11.5405 8.89736 11.862 8.68252 12.1329 8.41168C12.4037 8.14084 12.6185 7.81931 12.7651 7.46544C12.9117 7.11158 12.9871 6.73231 12.9871 6.34928C12.9871 5.96626 12.9117 5.58699 12.7651 5.23312C12.6185 4.87926 12.4037 4.55773 12.1329 4.28689C11.862 4.01605 11.5405 3.80121 11.1866 3.65464C10.8328 3.50806 10.4535 3.43262 10.0705 3.43262C9.29693 3.43262 8.55506 3.73991 8.00808 4.28689C7.4611 4.83387 7.15381 5.57574 7.15381 6.34928C7.15381 7.12283 7.4611 7.8647 8.00808 8.41168C8.55506 8.95866 9.29693 9.26595 10.0705 9.26595V9.26595Z"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M5.07021 3.95508C4.6702 4.23285 4.34616 4.60641 4.12769 5.04164C3.90921 5.47687 3.80325 5.95991 3.81949 6.44663C3.83574 6.93335 3.97366 7.40824 4.22067 7.82794C4.46768 8.24764 4.81591 8.59876 5.23354 8.84925"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M15.0703 3.95508C15.4565 4.22361 15.7718 4.58163 15.9895 4.99856C16.2072 5.41548 16.3207 5.87891 16.3203 6.34924C16.3203 7.34008 15.8261 8.21591 15.0703 8.74258"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M5.07031 17.5996V18.4329H15.0703V17.5996C15.0703 16.0463 15.0703 15.2696 14.8161 14.6579C14.478 13.8412 13.8294 13.1923 13.0128 12.8538C12.4003 12.5996 11.6236 12.5996 10.0703 12.5996C8.51698 12.5996 7.74031 12.5996 7.12865 12.8538C6.7241 13.0211 6.35648 13.2665 6.04677 13.5759C5.73707 13.8853 5.49135 14.2527 5.32365 14.6571C5.07031 15.2696 5.07031 16.0463 5.07031 17.5996Z"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M18.4035 18.4327V17.9327C18.4035 16.0661 18.4035 15.1327 18.0402 14.4194C17.7207 13.7919 17.2108 13.2817 16.5835 12.9619"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M1.73682 18.4327V17.9327C1.73682 16.0661 1.73682 15.1327 2.10015 14.4194C2.41958 13.7919 2.92951 13.2817 3.55682 12.9619"
        }
        stroke={"#2A5DE3"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </g>
    <defs>
      <clipPath id={"clip0_12885_84062"}>
        <rect
          width={"20"}
          height={"20"}
          fill={"white"}
          transform={"translate(0.0703125 0.932617)"}
        />
      </clipPath>
    </defs>
  </svg>
)

export default Staff
